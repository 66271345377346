import { createRouter, createWebHistory } from 'vue-router'

const routerOptions = [

  // HOME
  { path: "/", name: "Ínicio", component: "HomeView", meta: { requiresAuth: false, title: 'Grupo Agecob' } },
  { path: "/unidades", name: "Unidades" ,component: "UnidadesView", meta: { requiresAuth: false, title: 'Grupo Agecob - Unidades' } },
  { path: "/parceiros", name: "Parceiros", component: "ParceirosView", meta: { requiresAuth: false, title: 'Grupo Agecob - Parceiros' } },
  { path: "/gestao-pessoas", name: "Gestão de Pessoas", component: "GestaoPessoaView", meta: { requiresAuth: false, title: 'Grupo Agecob - Gestão de Pessoas' } },
  { path: "/codigo-etica", name: "Código de Ética", component: "CodigoEticaView", meta: { requiresAuth: false, title: 'Grupo Agecob - Código de ética' } },
  { path: "/programa-integridade", name: "Programa de Integridade", component: "ProgramaIntegridadeView", meta: { requiresAuth: false, title: 'Grupo Agecob - Programa de integridade' } },

  // CAREER
  { path: "/carreira", name: "Carreira na Agecob", component: "CarreiraView", meta: { requiresAuth: false, title: "Grupo Agecob - Carreira" } },
  { path: "/missao-valores", name: "Missão e valores" ,component: "MissaoValoresView", meta: { requiresAuth: false, title: "Grupo Agecob - Missão" } },

  // TRAINING
  { path: "/treinamento", name: "Treinamento" ,component: "TreinamentoView", meta: { requiresAuth: false, title: "Grupo Agecob - Treinamento" } },

  // CONTACT US
  { path: "/fale-conosco", name: "Fale Conosco", component: "FaleConoscoView", meta: { requiresAuth: false, title: "Grupo Agecob - Fale Conosco" } },
  { path: "/canais-contato", name: "Canais de Contato", component: "CanaisContatoView", meta: { requiresAuth: false, title: "Grupo Agecob - Contato" } },

  // WHATSAPP
  { path: "/whatsapp-view", name: "Painel Whatsapp", component: "WhatsappView", meta: { requiresAuth: true, title: "Grupo Agecob - Whatsapp View" } },

  // SLIDE AGECOB
  { path: "/slide-agecob", name: "Slides" ,component: "Apresentacao", meta: { requiresAuth: true, title: "Grupo Agecob - Slide Agecob" } },

  // AUTH
  { path: "/entrar", name: "Entrar", component: "Entrar", meta: { requiresAuth: false, title: "Grupo Agecob - Entrar" } },

  // JOBS
  { path: "/jobs", name: "Vagas", component: "Jobs", meta: { requiresAuth: false, title: "Grupo Agecob - Vagas disponíveis" } },

  // ROUTER ERROR
  { path: "/:pathMatch(.*)", name: "Não Encontrada", component: "404", meta: { title: 'Grupo Agecob - Página não encontrada' } },

];

const routes = routerOptions.map(route => {
  return {
    ...route,
    component: () => import(`../views/${route.component}.vue`)
  };
});

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

router.beforeEach(async (to, from) => {

  const validLogin = async () => {
    
    const axios = require('axios');
    let data = JSON.stringify();
    axios.defaults.baseURL = window.location.protocol + "//" + window.location.hostname + ":57608";
    let config = {
      method: 'POST',
      maxBodyLength: Infinity,
      withCredentials: true,
      url: 'api/post/valid/auth',
      headers: { 'Content-Type': 'application/json' },
      data: data
    };

    const result = await axios.request(config)

    try { 
      localStorage.setItem('auth', JSON.stringify(result.data));
    } catch (x) {
      localStorage.setItem('auth', JSON.parse(result.data));
    }
    
    return result;

  }

  if (to.meta.requiresAuth) {

    try { 
      var result = await validLogin();
    } catch (x) {
      console.log(x.code, x.message, x.response.data, x.response.status, x.response.statusText);
      return {
        path: '/entrar',
        query: { redirect: to.fullPath },
      }
    } 
  
  }

})

export default router
