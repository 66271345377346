// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

var statusDark = localStorage.getItem("theme-dark");

if (!statusDark) {
  if (window.matchMedia) {
    var statusDarkModeBrowser = window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (statusDarkModeBrowser) {
      statusDark = true
    } else {
      statusDark = false
    }
  } else {
    // Default (when Media-Queries are not supported)
  }
}

const themerDefault = {
  dark: statusDark ? true : false,
  colors: {
    background: '#153475',
    surface: '#3e6793',
    primary: '#1b4188',
    'primary-darken-1': '#3700B3',
    secondary: '#b0dee7',
    'secondary-darken-1': '22488c',
    error: '#B00020',
    info: '#193e84',
    success: '#4CAF50',
    green: '#4caf50',
    warning: '#FB8C00',
    gray: '#838383',
    CONNECTED: '#4CAF50',
    CLOSED: '#B00020',
    INITIALIZING: '#FB8C00',
    QRCODE: '#FB8C00'
  },
}

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    theme: {
      defaultTheme: 'themerDefault',
      themes: {
        themerDefault,
      },
    },
  }
)
