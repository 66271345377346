<template>
    <v-btn :variant="variantStyle ? variantStyle : 'outlined'" :color="typeBtn">
        {{ labelText }}
    </v-btn>
</template>

<script>

import { defineComponent } from 'vue'

export default defineComponent ({
    el: "#button",
    props: {
        labelText: String,
        typeBtn: String,
        variantStyle: String
    }
});

</script>

<style scoped>
button * {
    cursor: pointer !important;
}
</style>