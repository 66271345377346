import { createStore } from 'vuex'

export default createStore({
  state: {
    user: { id: 'aaa', name: 'Adsasas' },
    events: [
      {
        id: 1,
        title: 'title one',
        organizer: 'a'
      },
      {
        id: 2,
        title: 'title two',
        organizer: 'b'
      },
      {
        id: 3,
        title: 'title three',
        organizer: 'c'
      }
    ],
    categories: [
      'sustainability',
      'nature',
      'animal welfare',
      'housing',
      'education',
      'food',
      'community'
    ]
  },
  getters: {
    catLength: state => {
      return state.categories.length
    },
    doneToDos: state => {
      return state.todos.filter(todo => todo.done)
    },
    activeTodosCount: state => {
      return state.todos.filter(todo => !todo.done).length
    },
    getEventById: state => id => {
      return state.events.find(event => event.id === id)
    },
    getStatusCookie: state => {
      return state.statusCookie;
    }
  },
  mutations: {
    ADD_EVENT(state, event) {
      state.events.push(event);
    },
    ALTER_STATUS_COOKIE(state, event, key) {
      state.statusCookie = event
    } 
  },
  actions: {
    alterStatusCookie(status) {
      state.statusCookie(status)
    },
    createEvent({ commit }, event) {
      commit('ADD_EVENT', event);
    },
    alterStatus({ commit }, event) {
      commit('ALTER_STATUS_COOKIE', event);
    }
  },
  modules: {}
})
