<template>
  
  <bannerComponent class="hide-overflow" style="position: fixed; top: 0px;z-index: 100;" />
  <router-view></router-view>
</template>

<script>

// SYSTEM
import { defineComponent, Vue } from 'vue';

// COMPONENT
import bannerComponent from '@/components/Banner/bannerModel1.vue';

export default defineComponent({
  el: '#app',
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'Grupo Agecob';
      }
    },
  },
  metaInfo() {
    return {
      title: `${this.userData.name} - Epiloge`,
      meta: [
        { name: 'description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline },
        { property: 'og:title', content: this.userData.name + ' - Epiloge' },
        { property: 'og:site_name', content: 'Epiloge' },
        { property: 'og:description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline },
        { property: 'og:type', content: 'profile' },
        { property: 'og:url', content: 'https://epiloge.com/@' + this.userData.username },
        { property: 'og:image', content: this.aws_url + '/users/' + this.userData.profileurl + '-main.jpg' }
      ]
    }
  },
  components: {
    bannerComponent
  },
  created() {
    document.title = 'Carregando'
    document.getElementsByTagName("html")[0].lang = 'pt-BR';
  },
  mounted() {
    document.title = 'Grupo Agecob'
  }
});

</script>

<style>
* {
  cursor: default;
}

i {
  cursor: pointer;
}

.box-v-card::after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAI0lEQVQYV2NkwAEYCUq0t7cbgxRVVlaeBdFwHTgl0I3EaQcAR0EIB7IMQI0AAAAASUVORK5CYII=);
  bottom: 0;
  content: "";
  height: 100%;
  opacity: .6;
  position: absolute;
  width: 100%;
}

.email {
  text-decoration: none;
  cursor: pointer;
}

.scrollbar {
  margin-left: 30px;
  float: left;
  height: 300px;
  width: 65px;
  background: #F5F5F5;
  overflow-y: scroll;
  margin-bottom: 25px;
}

/* SCROLLBAR */
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  background-color: #3e6793;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(.5, rgba(255, 255, 255, .2)), color-stop(.5, transparent), to(transparent));
}

.glass {
  background: #3e679363;
  -webkit-backdrop-filter: blur(1.2rem);
  backdrop-filter: blur(1.2rem);
}
</style>