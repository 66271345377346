<template>
    <v-banner v-if="visibleBanner == 1 || !visibleBanner" lines="two" color="primary">
        <template v-slot:prepend>
            <v-avatar color="primary" icon="mdi-cookie"></v-avatar>
        </template>

        <v-banner-text>
            Ao clicar em "Aceitar Todos os cookies", você concorda com o armazenamento de cookies no seudispositivo para
            melhorar a navegação no site,
            analisar o uso do site e ajudar em nossos esforços em marketing.
            <a href="https://google.com.br">
                Política de Privacidade e cookies
            </a>
        </v-banner-text>

        <v-banner-actions>
            <fieldInputButton labelText="Rejeitar" typeBtn="" variantStyle="tonal" class="mr-4" @click="alterStatusBanner()"/>
            <fieldInputButton labelText="Aceitar todos os Cookies" typeBtn="white" variantStyle="tonal" @click="alterStatusBanner()" />
        </v-banner-actions>
    </v-banner>
</template>

<script>

// SYSTEM
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'

// COMPONENT
import fieldInputButton from '@/components/Button/button.vue'

export default defineComponent({
    el: '#bannerModel1',
    components: {
        fieldInputButton
    },
    data() {
        return {
            visibleBanner: 1,
        }
    },
    computed: {
        ...mapState(['user', 'categories']),
        ...mapGetters(['getEventById', 'catLength', 'getStatusCookie'])
    },
    methods: {

        alterStatusBanner: function (event) {
            this.visibleBanner = '0';
            localStorage.setItem('acceptCookie', 0);
            console.log("Hide");
        }

    },
    created() {
        
        this.visibleBanner = localStorage.getItem('acceptCookie')

    }
});
</script>

<style scoped>
a {
    text-decoration: underline;
    cursor: pointer;
    color: white;
}
</style>