import Vue, { createApp } from 'vue';
import App from './App.vue';
import Router from './router';
import Store from './store';
import Vuetify from './plugins/vuetify';
import Cookie from 'vue-cookies';
import { loadFonts } from './plugins/webfontloader';
import "vuetify/dist/vuetify.min.css";

loadFonts()

createApp(App)
  .use(Router)
  .use(Store)
  .use(Vuetify)
  .use(Cookie)
  .mount('#app')